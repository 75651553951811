import { getAuthState,
    getFeatureFlag,
    getFrameworkVars } from "@sparky/framework";

import { hasIOTSecurityRole, hasSLSRole } from "../rbac";

// to find out if tenant region is supported, it should
// not be part of the blocked regions list
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const isTenantRegionBlocked = () => {
    // since, this issue is going to happen in ngfw tenants only, check a ngfw instance for
    // region because it might be possible that prisma_access instande is not at all available
    const region = getAuthState()?.instances?.get("strata_insights")?.region;
    // pai API"s are not supported in following regions
    const unSupportedRegions = ["pl", "it", "es"];

    return region ? unSupportedRegions.includes(region) : false;
}

// export const rbacV2Check = (navKey: NavKey, auth: any): boolean => {
//     return hasRBACAccess(navKey, auth);
// };

// export const enforceRbacV2 = getFeatureFlag("vnr.rbac_v2") ?? false;

export const hasNetsecUI = (): boolean => {
    const auth = getAuthState();
    const frameworkVars = getFrameworkVars();
    const isADEMTier1Role = auth?.access?.hasRole("adem_tier_1_support");
    const { private_site_origin } = frameworkVars;
    const privateApp = Boolean(private_site_origin);
    const enableBlockIOTRoles = getFeatureFlag("vnr.rbac_block_iot_roles") ?? false;
    const enableBlockSLSRoles = getFeatureFlag("vnr.rbac_block_sls_roles") ?? false;
            
    if (privateApp) {
        return true
    }
    if (isTenantRegionBlocked()) {
        return false;
    }
    if (!getAuthState().instances?.isRunningStatus("logging_service")) {
        return false;
    }
    if (isADEMTier1Role) {
        return false
    }

    if(enableBlockIOTRoles && hasIOTSecurityRole(auth)) {
        return false;
    }
    
    if(enableBlockSLSRoles && hasSLSRole(auth)) {
        return false;
    }

    return true;
}

// AI access is available if ng_casb app_id or aperture app_id has entitlements with sku substring matching
// one of the sku's below
export const hasAIAccess = (): boolean => {
    //const auth = getAuthState();
    const frameworkVars = getFrameworkVars();
    const { private_site_origin } = frameworkVars;
    const privateApp = Boolean(private_site_origin);
    const auth = getAuthState();
    const enableBlockIOTRoles = getFeatureFlag("vnr.rbac_block_iot_roles") ?? false;
    const enableBlockSLSRoles = getFeatureFlag("vnr.rbac_block_sls_roles") ?? false;

    // Hide AI Access for fedMod tenants
    const { instances } = auth
    if (instances?.platform_region === "gov") {
        return false
    }
    
    if (privateApp) {
        return true
    }
    // casb tenants
    const casbEntitlements = auth?.instances?.get("ng_casb")?.entitlements
    const casbRegexList = [/AI-ACCESS/,/CASB-X/];
    const casbSkus = casbEntitlements?.filter(entitlement => {
        return casbRegexList.some(reg => {
            return reg.test(entitlement.sku);
        });
    });
    // aperture tenants check for PA ADDON
    // PA Addon license exist when all the 4 instances listed below are available
    const apertureEntitlements = getAuthState()?.instances?.get("aperture")?.entitlements;
    let inline = false, saas = false, sspm = false, paaddon = false;
    apertureEntitlements?.map((value, i) => {
        if(apertureEntitlements[i].app_id === "aperture" && (/SAASINL/).test(apertureEntitlements[i].license_type)) {
            inline = true
        }
        if(apertureEntitlements[i].app_id === "aperture" && (/SAASAPI/).test(apertureEntitlements[i].license_type)) {
            saas = true
        }
        if(apertureEntitlements[i].app_id === "aperture" && (/SAASSSPM/).test(apertureEntitlements[i].license_type)) {
            sspm = true
        }
        if(apertureEntitlements[i].app_id === "aperture" && (/PA-ADD/).test(apertureEntitlements[i].product_type)) {
            paaddon = true
        }
    });
    const apertureSkuAvail = inline && saas && sspm && paaddon;

    if(enableBlockIOTRoles && hasIOTSecurityRole(auth)) {
        return false;
    }
    
    if(enableBlockSLSRoles && hasSLSRole(auth)) {
        return false;
    }

    return (casbSkus?.length > 0 || apertureSkuAvail) &&
        auth?.instances?.isRunningStatus("logging_service");
}
