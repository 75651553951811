/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from "./file-code-solid.svg"
// import { ReactComponent as icon } from "./file-code-solid.svg"
import {
    CommandCenterIcon,
    HomeIcon
} from "@panwds/icons";
import pkg from "../package.json";
const App = async () => await import("./AppViewer");
const AiAccess = async () => await import("./ai-access/AiAccess");
const PDFReport = async () => await import("./ai-access/components/PDFReport/PDFReport");
const navAvailUtils = async () => await import("./utils/navAvailUtils")

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default async function init({ sparkyFramework, vars }) {
    console.info(
        `%c${pkg.name}: ${pkg.version}`,
        "font-size: 14px; color: white; background-color: black; border-radius: 8px; padding: 10px 15px;",
    );

    const netsecUIRoutes = [
        {
            key: "sase-home",
            title: "Command Center",
            path: "/aiops-free/home",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            icon: HomeIcon
        },
        {
            key: "sase-home",
            title: "Command Center",
            path: "/aiops-free/home/summary",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            icon: HomeIcon,
            hidden: true
        }, {
            key: "home-threats",
            title: "Command Center - Threats",
            path: "/aiops-free/home/threats",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            hidden: true
        }, {
            key: "home-operational_health",
            title: "Command Center - Operational Health",
            path: "/aiops-free/home/operational_health",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            hidden: true
        }, {
            key: "home-data_security",
            title: "Command Center - Data Security",
            path: "/aiops-free/home/data_security",
            noRBACheck: true,
            menuLink: "HOME",
            custom: "NetsecUIWrapper",
            hidden: true
        }
    ]
    const frameworkVars = sparkyFramework.getFrameworkVars() ?? {};
    const generateChildRoutesWithProps = (routes) => (
        routes.map((route) => ({
            ...route,
            mapStateToProps: (props) => ({
                ...props,
                routes: [route],
                vars,
                frameworkVars,
            }),
        }))
    );


    return async function tsgLoad() {
        const { hasNetsecUI, hasAIAccess } = await navAvailUtils()

        return {
            navigation: [{
                key: "home",
                title: "Command Center",
                path: "/home",
                icon: CommandCenterIcon,
                expandable: false,
                component: App,
                mergeNav: true,
                avail: hasNetsecUI,
                availFallbackRedirect: "/dashboards",
                mapStateToProps: (props) => ({
                    ...props,
                    routes: [{
                        key: "sase-home",
                        title: "Command Center",
                        icon: "menu-sase-home",
                        path: "/home/*",
                        noRBACheck: true,
                        menuLink: "HOME",
                        custom: "NetsecUI",
                    }],
                    vars,
                    frameworkVars,
                })
            },
            {
                key: "aiops4ngfw-free",
                expandable: false,
                path: "/aiops-free",
                hidden: true,
                mergeNav: true,
                noRBACheck: true,
                exact: false,
                children: [
                    {
                        key: "home",
                        title: "Command Center",
                        path: "/aiops-free/home",
                        icon: CommandCenterIcon,
                        expandable: false,
                        component: App,
                        mergeNav: true,
                        avail: hasNetsecUI,
                        navPath: "/aiops-free/home/summary",
                        availFallbackRedirect: "/aiops-free/dashboards",
                        mapStateToProps: (props) => ({
                            ...props,
                            routes: generateChildRoutesWithProps(netsecUIRoutes),
                            vars,
                            frameworkVars,
                        }),
                    }
                ]
            },
            {
                key: "insights",
                path: "/insights",
                mergeNav: true,
                children: [
                    {
                        key: "ac-home",
                        avail: hasAIAccess,
                        title: "AI Access",
                        path: "/insights/ai-access",
                        availFallbackRedirect: "/dashboards",
                    },
                    {
                        key: "ac-use-case-detail",
                        title: "Use Case Detail",
                        avail: hasAIAccess,
                        path: "/insights/ai-access/use-case",
                        hidden: true,
                        availFallbackRedirect: "/dashboards",
                    }
                ],
                component: AiAccess,
            },
            {
                key: "ai-access-pdf-report",
                path: "/ai-access-pdf-report",
                hidden: true,
                mergeNav: true,
                component: PDFReport,
                mapStateToProps: (props) => ({
                    ...props,
                    vars,
                    frameworkVars,
                }),
                contentClassName: "sparky-light-only",
            }
            ]
        };
    };
}
export { default as extensions } from "./extensions";
